import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { ThemeProvider } from "@material-tailwind/react";
import * as Sentry from "@sentry/react";
import { SENTRY_DSN, APP_ENVIRONMENT, CLARITY_ID, CLARITY_ENABLED } from './config'

import ErrorBoundary from './components/ErrorBoundary.jsx';

if (CLARITY_ENABLED && CLARITY_ID) {
  console.log("Adding Clarity tracking with ID: ", CLARITY_ID);
  (function (c, l, a, r, i, t, y) {
    c[a] = c[a] || function () {
      (c[a].q = c[a].q || []).push(arguments);  // NOSONAR - External code snippet
    };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", CLARITY_ID);
}

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    /*
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/disqo-dashboard\.azurewebsites\.net/],
    }),
    new Sentry.Replay(),
    */
  ],
  // Performance Monitoring
  tracesSampleRate: 0, // Capture 10% of the transactions
  replaysSessionSampleRate: 0, // Sample rate at 1%
  replaysOnErrorSampleRate: 0, // Sample rate at 10% for errors
  environment: APP_ENVIRONMENT || 'Local Development',
});

ReactDOM.createRoot(document.getElementById('root')).render(
  
    <ThemeProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </ThemeProvider>
)